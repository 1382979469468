
import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from './pages/home/Home';
import Privacy from "./pages/privacy/Privacy";
import InvoiceSuccess from "./pages/invoice/Success";
import InvoiceCancel from "./pages/invoice/Cancel";


import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const router = createBrowserRouter([
  {
    path: "/",
    Component: Home
  },
  {
    path: "/privacy-policy",
    Component: Privacy
  },
  {
    path: "/invoice/success",
    Component: InvoiceSuccess
  },
  {
    path: "/invoice/cancel",
    Component: InvoiceCancel
  }

]);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
