
import React from 'react';
import './Invoice.css';


export default function InvoiceCancel () {
  return (
    <div className="centered-content">
      <h1 className="bottom-space">Invoice Payment Cancelled!</h1> {/* Add your content here */}
      <h3>Please click invoice link in your SMS to try again</h3>
    </div>
  );
};
