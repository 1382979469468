
import React from 'react';
import './Invoice.css';


export default function InvoiceSuccess () {
  return (
    <div className="centered-content">
      <h1 className="bottom-space">Invoice Payment Successful!</h1>
      <h2>Thank you!</h2> {/* Add your content here */}
    </div>
  );
};
