
import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import logo from '../../images/logo-white.png';
import screenshotAgenda from '../../images/screenshot-agenda.webp';
import screenshots from '../../images/screenshots.webp';
import sergey from '../../images/sergey.webp';
import './Home.css';


export interface ContactUsForm {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}


const initialValue: ContactUsForm = {
  firstName: '',
  lastName: '',
  email: '',
  message: ''
};


export default function Home() {

  const [scroll, setScroll] = useState(false);
  const [currentSection, setCurrentSection] = useState('');
  const [form, setForm] = useState<ContactUsForm>({...initialValue});
  const [formErrors, setFormErrors] = useState<Partial<ContactUsForm>>({});


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 70);
    });
  }, []);


  useEffect(() => {
    const sectionNames = ['about', 'products', 'contact'];
    const sectionElements = sectionNames.map(sectionName => document.getElementById(sectionName)!);

    const interval = setInterval(() => {
      if ((window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 2)) {
        // We are at the bottom of the page
        const lastSection = sectionElements[sectionElements.length - 1];
        if (currentSection !== lastSection.id) return setCurrentSection(lastSection.id);    // NEW section found - Change
        return; // Section found, but it is the same - No changes
      }
      for (const sectionElement of sectionElements) {
        if (isInView(sectionElement)) {
          // console.log('isInView', sectionElement.id);
          if (currentSection !== sectionElement.id) return setCurrentSection(sectionElement.id);    // NEW section found - Change
          return; // Section found, but it is the same - No changes
        }
      }
      if (currentSection) return setCurrentSection('');   // Section not found, but was set previously - Clear
    }, 100);
    return () => clearInterval(interval);
  }, [currentSection]);


  const isInView = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const headerHeight = document.getElementById("navBar")!.offsetHeight;
    if (window.scrollY + headerHeight > element.offsetTop && rect.bottom > headerHeight) return true;
  };

  const sendEmail = async () => {
    const response = await fetch('https://api.sayt.io/public/help-request', {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(form)
    });
    const result = await response.json();
    if (!result.error) {
      setForm(currentForm => ({ ...initialValue }));
      setFormErrors(oldFormErrors => ({}));
      alert('Message successfully sent');
    } else {
      const formErrors = fillFormErrors(form, result);
      setFormErrors(oldFormErrors => formErrors);
    }
  }

  const onPropChange = (prop: keyof ContactUsForm, e: any) => {
    setForm(currentForm => ({ ...currentForm, [prop]: e.target.value }));
  }


  const fillFormErrors = (data: ContactUsForm, err: any): Partial<ContactUsForm> => {
    const errorData = handleZeusError(err);
    let result: Partial<ContactUsForm> = {};
    let populated = 0;
    if (errorData.details) {
      for (const detail of errorData.details) {
        const detailParts: any[] = detail.split('"');
        if (detailParts.length !== 3) continue;
        if (Object.keys(data).includes(detailParts[1])) {
          // @ts-ignore
          result[detailParts[1]] = capitalizeFirstLetter(detailParts[2].trim());
          populated++;
        }
      }
    }
    if (!populated) {
      alert(errorData.message);
      return {};
    }
    return result;
  }


  const handleZeusError = (data: any): any => {
    if (data.error) return { ...data.error, error: true };
    return { message: 'Network error. Please try again later.', error: true };
  }

  const capitalizeFirstLetter = (str: string): string => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }


  const navClassName = 'rowGradient navBar ' + (scroll ? "shadowed" : "");

  return (
    <>
      <Row className={'rowGradient rowTop'}>
        <Navbar id="navBar" className={navClassName} collapseOnSelect expand="lg" fixed={'top'} data-bs-theme="dark" bg="primary">
          <Container>
            <Navbar.Brand href="/">
              <img className={'logoImg'} src={logo} alt="Homepage"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#about" className={'navItem' + (currentSection === 'about' ? ' active' : '')}>About Us</Nav.Link>
                <Nav.Link href="#products" className={'navItem' + (currentSection === 'products' ? ' active' : '')}>Products</Nav.Link>
                <Nav.Link href="#contact" className={'navItem' + (currentSection === 'contact' ? ' active' : '')}>Contact</Nav.Link>
              </Nav>
              {/*<Nav>*/}
              {/*    <Nav.Link href="#deets">More deets</Nav.Link>*/}
              {/*    <Nav.Link eventKey={2} href="#memes">*/}
              {/*        Dank memes*/}
              {/*    </Nav.Link>*/}
              {/*</Nav>*/}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container>
          <Row>
            <Col lg={7} sm={8} xs={12}>
              <span className={'primaryTagline'}>Provide your customers with a digital experience that they expect.</span>
              <span className={'secondaryTagline'}>Take your business to the next level today and get ahead of the competition. Our solutions help entrepreneurs around the world to deliver exceptional digital experiences to <u>modern</u> customers.</span>
            </Col>
            <Col lg={5} sm={4} xs={12} className={'text-center align-items-center'}>
              <img className={'welcomeImg'} src={screenshotAgenda} alt="SAY Business Scheduling screenshot"/>
            </Col>
          </Row>
        </Container>
      </Row>


      <Row id="about" className={'rowDefault'}>
        <Container>
          <Row>
            <Col lg={5} sm={6} xs={12}>
              <img className={'sergeyImg'} src={sergey} alt="Sergey Yarotskiy @ SAY Technology"/>
            </Col>
            <Col lg={7} sm={6} xs={12} className={'align-middle centerVContent'}>
              <div>
                <span className={'aboutUsHeader'}>About Us</span>
                <span className={'aboutUsText'}>"We are passionate about technology. Creating software is an art. But it is also much more than that. It is the ability to help people. Here at SAY Technology, we help entrepreneurs worldwide to grow their businesses by creating a loyal customer base and saving management time."</span>
                <span className={'aboutUsSignature'}>Sergey Yarotskiy</span>
                <span className={'aboutUsRole'}>Founder, CTO</span>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>


      <Row id="products" className={'rowDefault rowGradient'}>
        <Container>
          <Row>
            <Col lg={4} xs={12}>
              <span className={'productsHeader'}>SAY Business Scheduling</span>
              <span className={'productsText'}>Modern customers expect from service providers a certain quality of service and a specific digital experience. Such as appointment reminders, notifications, feedback collection, and web presence. It is a big overhead to manage all that. Our products eliminate the need for service providers to manually manage the above activities, which makes their lives easier.</span>
            </Col>
            <Col lg={8} xs={12} className={'text-center align-items-center'}>
              <img className={'productsImg'} src={screenshots} alt="SAY Business Scheduling screenshots"/>
            </Col>
          </Row>
        </Container>
      </Row>


      <Row id={'contact'} className={'rowDefault'}>
        <Form>
          <Row className={'text-center contactUsHeader'}>
            <h1>Contact Us</h1>
          </Row>
          <Row className={'justify-content-lg-center'}>
            <Col lg={3} sm={6} xs={12}>
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label>First name</Form.Label>
                <Form.Control
                  className={'formPaddedRight'}
                  required
                  placeholder=""
                  value={form.firstName}
                  onChange={(e) => onPropChange("firstName", e)}
                />
                <Form.Control.Feedback className={formErrors.firstName ? 'forceDisplay' : ''} type="invalid">{formErrors.firstName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={3} sm={6} xs={12}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                <Form.Label>Last name</Form.Label>
                <Form.Control
                  required
                  placeholder=""
                  value={form.lastName}
                  onChange={(e) => onPropChange("lastName", e)}
                />
                <Form.Control.Feedback className={formErrors.lastName ? 'forceDisplay' : ''} type="invalid">{formErrors.lastName}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'justify-content-lg-center'}>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  placeholder=""
                  value={form.email}
                  onChange={(e) => onPropChange("email", e)}
                />
                <Form.Control.Feedback className={formErrors.email ? 'forceDisplay' : ''} type="invalid">{formErrors.email}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'justify-content-lg-center'}>
            <Col lg={6}>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea4">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  required as="textarea"
                  rows={5}
                  value={form.message}
                  onChange={(e) => onPropChange("message", e)}
                />
                <Form.Control.Feedback className={formErrors.message ? 'forceDisplay' : ''} type="invalid">{formErrors.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row className={'text-center justify-content-center justify-content-lg-center'}>
            <Col lg={6}>
              <Button variant='custom' onClick={sendEmail}>Submit form</Button>
            </Col>
          </Row>
        </Form>
      </Row>
      <Row className={'footer rowGradient align-items-center'}>
        <Container>
          SAY Technology @ 2023
        </Container>
      </Row>
    </>
  );
}
